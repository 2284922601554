import isNil from 'lodash/isNil';
import size from 'lodash/size';
import startCase from 'lodash/startCase';

import { FLEET_TYPES } from '@/constants/fleetTypes.const';
import { DATE_UNITS } from '@/constants/dates';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const FLEET_OPTONS = [
  {
    label: $t('business_profile.booking_overview.filters.input_all_option'),
    value: null,
  },
  {
    label: $t(`constants.fleet_type.${FLEET_TYPES.open}`),
    value: FLEET_TYPES.open,
  },
  {
    label: $t(`constants.fleet_type.${FLEET_TYPES.dedicated}`),
    value: FLEET_TYPES.dedicated,
  },
];

export const FILTER_TYPES = {
  fleetType: { type: 'fleet_type', validator: value => !isNil(value) },
  location: { type: 'location', validator: value => !isNil(value) },
  vehicleCategory: {
    type: 'vehicle_category',
    validator: value => !isNil(value),
  },
  search: { type: 'text', validator: value => size(value) > 2 },
};

export const DEFAULT_CITY_LIST = [
  {
    name: $t('business_profile.booking_overview.filters.input_city_placeholder'),
    uuid: null,
  },
];

export const VIEW_TYPES = [
  {
    label: $t('business_profile.booking_overview.view_labels.one_day'),
    value: 1,
    config: {
      days: 1,
      scale: 'CellDuration',
      cellDuration: 15,
      timeHeaders: [
        { groupBy: startCase(DATE_UNITS.day), format: DATETIME_FORMATS.dobDayPilot },
        { groupBy: startCase(DATE_UNITS.hour) },
      ],
    },
  },
  {
    label: $t('business_profile.booking_overview.view_labels.three_days'),
    value: 3,
    config: {
      days: 3,
      scale: startCase(DATE_UNITS.hour),
      timeHeaders: [
        { groupBy: startCase(DATE_UNITS.month), format: DATETIME_FORMATS.shortMonthLongYear },
        { groupBy: startCase(DATE_UNITS.day), format: DATETIME_FORMATS.shortDay },
        { groupBy: startCase(DATE_UNITS.hour) },
      ],
    },
  },
  {
    label: $t('business_profile.booking_overview.view_labels.seven_days'),
    value: 7,
    config: {
      days: 7,
      scale: startCase(DATE_UNITS.day),
      timeHeaders: [
        { groupBy: startCase(DATE_UNITS.month), format: DATETIME_FORMATS.shortMonthLongYear },
        { groupBy: startCase(DATE_UNITS.day), format: DATETIME_FORMATS.mediumDay },
      ],
    },
  },
];
