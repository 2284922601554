import snakeCase from 'lodash/snakeCase';
import { COUNTRIES_ISO_CODES, getValue } from '@emobg/web-utils';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const specificFieldsKeys = Object.freeze({
  codiceFiscale: 'codiceFiscale',
  siretNumber: 'siretNumber',
});

export const companyCountryFields = {
  [COUNTRIES_ISO_CODES.france]: [
    specificFieldsKeys.siretNumber,
  ],
  [COUNTRIES_ISO_CODES.italy]: [
    specificFieldsKeys.codiceFiscale,
  ],
};

export const requiredUserDataFields = ['address', 'address_details', 'commercial_name', 'size', 'phone'];

export const specificInputFields = ({ companyData }) => ({
  [specificFieldsKeys.codiceFiscale]: {
    props: {
      name: 'codice_fiscale',
      disabled: true,
      label: $t('business_profile.company.codice_fiscale'),
      placeholder: $t('business_profile.company.codice_fiscale'),
      value: getValue(companyData, snakeCase(specificFieldsKeys.codiceFiscale), undefined),
    },
    dataTestId: 'codice_fiscale-input',
    countries: [COUNTRIES_ISO_CODES.italy],
  },
  [specificFieldsKeys.siretNumber]: {
    props: {
      name: 'siret_number',
      disabled: true,
      label: $t('business_profile.company.siret_number'),
      placeholder: $t('business_profile.company.siret_number'),
      value: getValue(companyData, snakeCase(specificFieldsKeys.siretNumber), undefined),
    },
    dataTestId: 'siret_number-input',
    countries: [COUNTRIES_ISO_CODES.france],
  },
});
