<template>
  <div class="CompanyAdminReporting">
    <h2 class="mb-3">
      {{ $t('business_profile.reporting.title') }}
    </h2>
    <ui-loader v-if="isLoading" />
    <div v-else>
      <div
        v-if="signedUrl"
        class="w-100 px-0"
      >
        <iframe
          :src="signedUrl"
          class="w-100"
          height="800"
          frameborder="0"
          allowtransparency
          data-test-id="report-iframe"
        />
      </div>
      <figure
        v-else
        class="d-flex justify-content-center"
      >
        <img
          :src="genericErrorIllustration"
          class="w-50"
          data-test-id="report-failed-image"
          alt="error"
        >
      </figure>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { LOG_TYPE, logger } from '@emobg/web-utils';

import { external } from '@emobg/web-api-client';

import { nameSpace as CompanyNameSpace } from '@/vue/stores/Company/CompanyStore';

import cfg from '@/config';
import { genericError } from '@/utils/publicImages';
import Api from '@/vue/api/internal';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

export default {
  name: 'CompanyAdminReporting',
  setup() {
    const { notifyError } = useNotifications();
    return {
      notifyError,
    };
  },
  data() {
    return {
      isLoading: false,
      signedUrl: undefined,
    };
  },

  computed: {
    ...mapGetters(CompanyNameSpace, ['getCompanyData']),
  },

  created() {
    this.genericErrorIllustration = genericError;
  },

  mounted() {
    this.fetchSignedUrl();
  },

  methods: {
    async fetchSignedUrl() {
      this.isLoading = true;
      try {
        if (this.$featureFlag.flags.displayMetabaseCompanyAdminReport) {
          this.signedUrl = await external.app.getCompanyAdminReport({
            cs_operator_uuid: get(this, 'getCompanyData.dedicated_cs_operator_uuid'),
          });
        } else {
          // TODO: [SBCSB-1877] - Deprecate this call when all cs operatos get move into Metabase
          const response = await Api.businessIntelligence.company.report({
            companyUuid: get(this, 'getCompanyData.uuid'),
            accessKey: get(cfg, 'data.modeAnalyticsAccessKey'),
          });

          this.signedUrl = get(response, 'data.signedUrl');
        }
      } catch (error) {
        logger.message(`Error retrieving signed url report: ${error.message}`, LOG_TYPE.error);

        this.notifyError({
          text: this.$t('notifications.whooops'),
          textAction: this.$t('modal.common.ok'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>
