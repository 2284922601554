import startCase from 'lodash/startCase';
import { ICONS } from '@emobg/web-components';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const TRANSLATIONS_KEY_DICT = {
  start: 'start_date',
  end: 'end_date',
  locationName: 'location',
  destinationLocationName: 'destination_location',
  useCaseProfile: 'booking_type',
  bookingTripType: 'booking_trip_type',
  phone: 'phone_number',
  email: 'email',
  costCenter: 'cost_center',
};

export const FIELDS = {
  costCenter: 'cost_center',
};

export const resourcesBubbleConfig = ({ mileageSymbol = '' }) => ({
  pax: {
    icon: ICONS.personal,
    appendText: startCase($t('business_profile.booking_overview.bubble.persons')),
  },
  transmission: {
    icon: ICONS.transmission,
  },
  engine: {
    icon: ICONS.fuelDispenser,
  },
  mileage: {
    appendText: mileageSymbol,
  },
});
