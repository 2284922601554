import { getValue } from '@emobg/web-utils';

export const getOperatorsForLocations = companyData => {
  const operators = [];
  const csOperatorUuid = getValue(companyData, 'cs_operator_uuid', undefined);
  const dedicatedCSOperator = getValue(companyData, 'dedicated_cs_operator_uuid', undefined);
  const isOpenFleet = getValue(companyData, 'company_fleets.open', undefined);
  if (isOpenFleet && csOperatorUuid) {
    operators.push(csOperatorUuid);
  }

  if (dedicatedCSOperator) {
    operators.push(dedicatedCSOperator);
  }
  return operators;
};
