import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export function companySizeOptions() {
  return [
    {
      label: $t('business_profile.company.number_of_employees.unknown'),
      value: 'unknown',
    },
    {
      label: $t('business_profile.company.number_of_employees.size1'),
      value: '2-5',
    },
    {
      label: $t('business_profile.company.number_of_employees.size2'),
      value: '6-10',
    },
    {
      label: $t('business_profile.company.number_of_employees.size3'),
      value: '11-20',
    },
    {
      label: $t('business_profile.company.number_of_employees.size4'),
      value: '21-50',
    },
    {
      label: $t('business_profile.company.number_of_employees.size5'),
      value: '51-100',
    },
    {
      label: $t('business_profile.company.number_of_employees.size6'),
      value: '101-200',
    },
    {
      label: $t('business_profile.company.number_of_employees.size7'),
      value: '> 200',
    },
  ];
}
