var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CompanyAdminBookingOverview container mt-5 pb-4",attrs:{"data-test-id":"booking-overview"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('SideBar.sections.company_administration.subsections.booking_overview.title'))+" ")]),(_vm.get(_vm.$refs, 'daypilot.dp'))?_c('div',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-3"},[_c('ui-select',{attrs:{"value":_vm.citySelected,"label":_vm.$t('business_profile.booking_overview.filters.label_select_city'),"placeholder":_vm.$t('business_profile.booking_overview.filters.input_city_placeholder'),"data-test-id":"city_selector","name":"city"},domProps:{"options":_vm.cityList.map(city => ({
            value: city.uuid,
            label: city.name,
          }))},on:{"selectoption":({ detail }) => {
            _vm.citySelected = detail;
            _vm.getFleetAndBookings(detail)
          }}})],1),_c('div',{staticClass:"col-9"},[_c('ui-text-input',{attrs:{"value":_vm.textSearched,"label":_vm.$t('business_profile.booking_overview.filters.label_input_search'),"placeholder":_vm.$t('business_profile.booking_overview.filters.input_search_box_placeholder'),"icon-left":_vm.ICONS.search,"reverse":"","name":"search","data-test-id":"search_input"},on:{"changevalue":({ detail }) => {
            _vm.textSearched = detail;
            _vm.filterBy(_vm.FILTER_TYPES.search, detail);
          }}})],1)]),_c('div',{staticClass:"row mb-4"},[_c('ui-select',{staticClass:"col-3 mt-2",attrs:{"value":_vm.fleetSelected,"label":_vm.$t('business_profile.booking_overview.filters.label_select_fleet_type'),"placeholder":_vm.$t('business_profile.booking_overview.filters.input_fleet_type_placeholder'),"data-test-id":"fleet_selector","name":"fleet-type"},domProps:{"options":_vm.FLEET_OPTONS},on:{"selectoption":({ detail }) => {
          _vm.fleetSelected = detail;
          _vm.filterBy(_vm.FILTER_TYPES.fleetType, detail)
        }}}),_c('ui-select',{staticClass:"col-3 mt-2",attrs:{"value":_vm.get(_vm.locationSelected, 'name'),"label":_vm.$t('business_profile.booking_overview.filters.label_select_station'),"placeholder":_vm.$t('business_profile.booking_overview.filters.input_location_placeholder'),"data-test-id":"location_selector","name":"Station"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.displayedLocations),function(location,index){return _c('div',{key:index,staticClass:"p-2 cursor-pointer emobg-background-color-ground-lighter-hover",on:{"click":function($event){return _vm.onSelectLocation(location)}}},[_vm._v(" "+_vm._s(location.name)+" ")])}),0)]),_c('ui-select',{staticClass:"col-3 mt-2",attrs:{"value":_vm.vehiclesCategorySelected,"placeholder":_vm.$t('business_profile.booking_overview.filters.input_category_placeholder'),"label":_vm.$t('business_profile.booking_overview.filters.label_select_vehicle_category'),"data-test-id":"vehicle-category_selector","name":"category"},domProps:{"options":_vm.vehiclesCategoryList},on:{"selectoption":({ detail }) => {
          _vm.vehiclesCategorySelected = detail;
          _vm.filterBy(_vm.FILTER_TYPES.vehicleCategory, detail)
        }}}),_c('ui-button',_vm._b({staticClass:"col-2 align-self-end",attrs:{"disabled":_vm.isEmpty(_vm.filters),"data-test-id":"clear-filters_button"},on:{"clickbutton":_vm.removeAllFilters}},'ui-button',_vm.fetchButtonSpecs({ buttonType: _vm.THEME_BUTTON_TYPES.SECONDARY }),false),[_vm._v(" "+_vm._s(_vm.$t("business_profile.booking_overview.filters.button_text"))+" ")])],1)]):_vm._e(),_c('ui-card',{staticClass:"d-block my-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('ui-button',_vm._b({attrs:{"data-test-id":"today-button"},on:{"clickbutton":() => _vm.date = _vm.moment()}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('business_profile.booking_overview.today'))+" ")]),_c('ui-datetimepicker',{attrs:{"data-test-id":"date_selected-datepicker","skiptime":""},domProps:{"date":_vm.date},on:{"datechanged":({ detail }) => _vm.debounceOnChangeDatetimepicker(detail)}}),_c('ui-button-segments',{staticClass:"Ui-ButtonSegments--minw-initial",attrs:{"value":_vm.currentViewTypeIndex,"color":_vm.COLORS.primary,"default-color":_vm.GRAYSCALE.inkLight,"data-test-id":"set_days-segmented_button"},domProps:{"options":_vm.views},on:{"clickbuttonsegment":({ detail }) => _vm.currentViewType = _vm.find(_vm.views, ['value', detail])}})],1)]),_c('Daypilot',{ref:"daypilot",attrs:{"resources":_vm.resources,"events":_vm.events,"date":_vm.date,"view-type":_vm.currentViewType,"events-bubble":_vm.getEventsBubble,"resources-bubble":_vm.getResourcesBubble,"is-loading":_vm.isLoading,"data-test-id":"daypilot-component"},on:{"update:filter-resources":_vm.onFilterResources,"update:is-empty":isEmpty => _vm.isEmptyList = isEmpty}}),(_vm.isLoading)?_c('ui-loader'):_c('div',{staticClass:"container pt-5"},[(_vm.isEmptyList)?_c('EmptyStateComponent',{staticClass:"h-100 p-4 d-flex",attrs:{"src":_vm.emptyStateImage,"title":_vm.$t('business_profile.booking_overview.no_results'),"has-background":""}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }