<template>
  <div class="flavor-ecod EventBubble m-4">
    <div
      v-cs-mask
      class="emobg-font-weight-bold mb-4"
    >
      {{ customer.firstName }} {{ customer.lastName }}
    </div>

    <div
      v-for="(value, key) in customerSanitized"
      :key="key"
      v-cs-masked="includes(maskedFields, key)"
      class="my-1 d-flex"
    >
      <p class="font-weight-bold mr-1">
        {{ $t(`common.${translationsKeyDict[key]}`) }}:
      </p>
      <span v-if="key === fields.costCenter">
        {{ value }}
      </span>
      <a
        :href="`mailto:${value}`"
        class="emobg-color-primary"
      >
        {{ value }}
      </a>
    </div>

    <div class="my-2">
      <div
        v-for="(value, key) in reservationSanitized"
        :key="key"
        class="my-1 d-flex"
      >
        <p class="font-weight-bold mr-1">
          {{ $t(`business_profile.booking_overview.bubble.${translationsKeyDict[key]}`) }}:
        </p>
        <span v-if="moment(value).isValid()">
          {{ formatDate(value, datetimeFormats.shortPrettyDatetime) }}
        </span>
        <span v-else>
          {{ startCase(value) }}
        </span>
      </div>
    </div>

    <div
      v-if="reservation.status"
      class="my-2 d-flex align-items-baseline"
    >
      <p class="font-weight-bold mr-1">
        {{ $t('business_profile.booking_overview.bubble.booking_status') }}:
      </p>
      <ui-badge
        :color="!includes(reservation.status, [bookingStatus.finished, bookingStatus.cancelled])
          ? COLORS.success
          : GRAYSCALE.ground"
        :size="SIZES.small"
        class="ml-2"
      >
        {{ startCase(reservation.status) }}
      </ui-badge>
    </div>
  </div>
</template>

<script>
import { CsMask } from '@emobg/vue-base';
import startCase from 'lodash/startCase';
import snakeCase from 'lodash/snakeCase';
import includes from 'lodash/includes';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from '@emobg/web-utils';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { BOOKING_STATUS } from '@/constants/bookingStatus.const';

import { FIELDS, TRANSLATIONS_KEY_DICT } from './constants/fieldsConfig';

export default {
  name: 'EventBubble',
  directives: { CsMask },
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.datetimeFormats = DATETIME_FORMATS;
    this.bookingStatus = BOOKING_STATUS;
    this.maskedFields = ['email', 'phone'];
    this.reservationSanitized = omit(omitBy(this.reservation, isEmpty), ['status']);
    this.customerSanitized = omit(omitBy(this.customer, isEmpty), ['firstName', 'lastName']);
    this.translationsKeyDict = TRANSLATIONS_KEY_DICT;
    this.fields = FIELDS;
  },

  methods: {
    startCase,
    snakeCase,
    includes,
    formatDate,
  },
};

</script>
