<template>
  <div class="p-4">
    <figure>
      <img
        v-if="vehicle.image"
        :src="vehicle.image"
        class="mr-2"
        style="width: 125px;"
      >
      <figcaption class="emobg-font-weight-bold my-1">
        {{ vehicle.brand }} {{ vehicle.model }}
      </figcaption>
    </figure>

    <div
      v-for="(value, key) in vehicleSanitized"
      :key="key"
      class="my-2"
    >
      <ui-icon
        v-if="get(bubbleConfig[key], 'icon')"
        :icon="get(bubbleConfig[key], 'icon')"
        :size="ICONS_SIZES.small"
        :color="COLORS.primary"
        class="mr-2"
      />
      <span v-else>
        <strong>
          {{ $t(`business_profile.booking_overview.bubble.${key}`) }}:
        </strong>
      </span>

      <span>
        {{ startCase(value) }}
      </span>
      <span v-if="get(bubbleConfig[key], 'appendText')">
        {{ get(bubbleConfig[key], 'appendText') }}
      </span>
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { resourcesBubbleConfig } from './constants/fieldsConfig';

export default {
  name: 'ResourceBubble',

  props: {
    vehicle: {
      type: Object,
      default: () => ({}),
    },
    mileageSymbol: {
      type: String,
      default: '',
    },
  },

  created() {
    this.vehicleSanitized = omit(omitBy(this.vehicle, isEmpty), ['image', 'brand', 'model']);
    this.bubbleConfig = resourcesBubbleConfig({ mileageSymbol: this.mileageSymbol });
  },

  methods: {
    startCase,
    get,
  },

};

</script>
