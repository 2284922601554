<template>
  <div :class="`js-${id}`">
    <div
      v-show="!isLoading"
      :id="id"
    />
  </div>
</template>

<script>
import moment from 'moment';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import random from 'lodash/random';
import delay from 'lodash/delay';

import { DELAY } from '@emobg/web-utils';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

import { DAYPILOT_DEFAULT_CONFIG } from './constants/daypilotConfig';

const SCRIPT_URL = '/static/assets/js/daypilot-all.min.js';
export default {
  name: 'Daypilot',

  props: {
    date: {
      type: Object,
      default: moment(),
    },
    resources: {
      type: Array,
      default: () => [],
    },
    onFilterResources: {
      type: Function,
      default: () => ({}),
    },
    resourcesBubble: {
      type: Function,
      default: () => ({}),
    },
    events: {
      type: Array,
      default: () => [],
    },
    eventsBubble: {
      type: Function,
      default: () => ({}),
    },
    viewType: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dp: null,
    };
  },

  watch: {
    'dp.rowlist': {
      handler(rows) {
        const rowsFiltered = (rows.filter(item => !item.hidden));
        const isEmptyList = isEmpty(rowsFiltered) || rowsFiltered.isEmpty();
        this.$emit('update:is-empty', isEmptyList);
      },
    },

    resources: {
      handler(resources) {
        if (this.isValidData(resources)) {
          this.setResources(resources);
          this.update();
        }
      },
    },

    events: {
      handler(events) {
        if (this.isValidData(events)) {
          this.setEvents(events);
          this.update();
        }
      },
    },

    date: {
      handler(date) {
        this.setDate(date);
      },
      deep: true,
    },
    viewType: {
      handler(value) {
        this.dp = Object.assign(this.dp, get(value, 'config'));
        this.update();
      },
    },
  },

  created() {
    this.id = `Daypilot-${random(0, 100)}`;
    this.defaultConfig = DAYPILOT_DEFAULT_CONFIG;
  },

  mounted() {
    if (isNull(this.dp)) {
      const script = document.createElement('script');
      script.onload = this.onLoad;
      script.src = SCRIPT_URL;
      document.body.appendChild(script);
    }
  },

  methods: {
    isValidData(data) {
      return !isNil(this.dp) && isArray(data);
    },
    onLoad() {
      this.dp = new window.DayPilot.Scheduler(this.id);
      this.dp = Object.assign(
        this.dp,
        this.defaultConfig,
        {
          bubble: this.onLoadEventsBubble(),
          resourceBubble: this.onLoadResourcesBubble(),
          onRowFilter: args => this.$emit('update:filter-resources', { args }),
        },
      );

      this.setDate(this.date);

      this.dp.separator = moment().format(DATETIME_FORMATS.fullFormatTimezone);
      this.dp.init();

      if (!isEmpty(this.viewType)) {
        this.dp = Object.assign(this.dp, get(this.viewType, 'config'));
      }

      if (this.isValidData(this.resources)) {
        this.setResources(this.resources);
        this.update();
      }

      if (this.isValidData(this.events)) {
        this.setEvents(this.events);
        this.update();
      }
    },

    onLoadEventsBubble() {
      return this.eventsBubble({
        Bubble: window.DayPilot.Bubble,
        find: this.dp.rows.find,
      });
    },

    onLoadResourcesBubble() {
      return this.resourcesBubble({
        Bubble: window.DayPilot.Bubble,
        find: this.dp.rows.find,
      });
    },

    setResources(resources) {
      this.dp.resources = resources;
    },

    setEvents(events) {
      this.dp.events.list = events;
    },

    setDate(newDate) {
      const momentDate = moment(newDate).isValid()
        ? newDate
        : moment();
      this.dp.startDate = momentDate.format(DATETIME_FORMATS.fullFormatTimezone);
      this.update();
    },

    filterEvents(filters) {
      this.dp.events.filter(filters);
    },

    filterResources(filters) {
      this.dp.rows.filter(filters);
    },

    update() {
      delay(() => this.dp.update(), DELAY.medium);
    },
  },
};

</script>
