import moment from 'moment';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

export const DAYPILOT_DEFAULT_CONFIG = {
  locale: 'es-es',
  cellWidthSpec: 'Auto',
  treeEnabled: true,
  timeFormat: 'Clock24Hours',
  treePreventParentUsage: true,
  height: '100%',
  heightSpec: 'Auto',
  cellSweeping: true,
  cellSweepingCacheSize: 2000,
  dynamicEventRendering: 'Progressive',
  dynamicEventRenderingCacheSweeping: true,
  dynamicEventRenderingCacheSize: 1000,
  scrollDelayCells: 100,
  progressiveRowRendering: true,
  progressiveRowRenderingPreload: 50,
  rowHeaderWidth: 220,
  rowMinHeight: 40,
  rowHeaderWidthAutoFit: false,
  eventMoveHandling: 'Disabled',
  eventEditHandling: 'Disabled',
  eventResizeHandling: 'Disabled',
  eventSelectHandling: 'Disabled',
  eventHeight: 40,
  useEventBoxes: 'Never',
  durationBarVisible: false,
  separators: [{ color: 'Red', location: moment().format(DATETIME_FORMATS.fullFormatTimezone) }],
};
