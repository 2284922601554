<template>
  <div
    data-test-id="booking-overview"
    class="CompanyAdminBookingOverview container mt-5 pb-4"
  >
    <h2 class="mb-3">
      {{ $t('SideBar.sections.company_administration.subsections.booking_overview.title') }}
    </h2>
    <div v-if="get($refs, 'daypilot.dp')">
      <div class="row mb-3">
        <div class="col-3">
          <ui-select
            :value="citySelected"
            :label="$t('business_profile.booking_overview.filters.label_select_city')"
            :placeholder="$t('business_profile.booking_overview.filters.input_city_placeholder')"
            :options.prop="cityList.map(city => ({
              value: city.uuid,
              label: city.name,
            }))"
            data-test-id="city_selector"
            name="city"
            @selectoption="({ detail }) => {
              citySelected = detail;
              getFleetAndBookings(detail)
            }"
          />
        </div>
        <div class="col-9">
          <ui-text-input
            :value="textSearched"
            :label="$t('business_profile.booking_overview.filters.label_input_search')"
            :placeholder="$t('business_profile.booking_overview.filters.input_search_box_placeholder')"
            :icon-left="ICONS.search"
            reverse
            name="search"
            data-test-id="search_input"
            @changevalue="({ detail }) => {
              textSearched = detail;
              filterBy(FILTER_TYPES.search, detail);
            }"
          />
        </div>
      </div>

      <div class="row mb-4">
        <ui-select
          :value="fleetSelected"
          :label="$t('business_profile.booking_overview.filters.label_select_fleet_type')"
          :placeholder="$t('business_profile.booking_overview.filters.input_fleet_type_placeholder')"
          :options.prop="FLEET_OPTONS"
          data-test-id="fleet_selector"
          name="fleet-type"
          class="col-3 mt-2"
          @selectoption="({ detail }) => {
            fleetSelected = detail;
            filterBy(FILTER_TYPES.fleetType, detail)
          }"
        />
        <ui-select
          :value="get(locationSelected, 'name')"
          :label="$t('business_profile.booking_overview.filters.label_select_station')"
          :placeholder="$t('business_profile.booking_overview.filters.input_location_placeholder')"
          data-test-id="location_selector"
          name="Station"
          class="col-3 mt-2"
        >
          <div slot="content">
            <div
              v-for="(location, index) in displayedLocations"
              :key="index"
              class="p-2 cursor-pointer emobg-background-color-ground-lighter-hover"
              @click="onSelectLocation(location)"
            >
              {{ location.name }}
            </div>
          </div>
        </ui-select>

        <ui-select
          :value="vehiclesCategorySelected"
          :placeholder="$t('business_profile.booking_overview.filters.input_category_placeholder')"
          :label="$t('business_profile.booking_overview.filters.label_select_vehicle_category')"
          :options.prop="vehiclesCategoryList"
          data-test-id="vehicle-category_selector"
          name="category"
          class="col-3 mt-2"
          @selectoption="({ detail }) => {
            vehiclesCategorySelected = detail;
            filterBy(FILTER_TYPES.vehicleCategory, detail)
          }"
        />

        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
          :disabled="isEmpty(filters)"
          data-test-id="clear-filters_button"
          class="col-2 align-self-end"
          @clickbutton="removeAllFilters"
        >
          {{ $t("business_profile.booking_overview.filters.button_text") }}
        </ui-button>
      </div>
    </div>

    <ui-card class="d-block my-4">
      <div class="d-flex justify-content-between">
        <ui-button
          v-bind="fetchButtonSpecs()"
          data-test-id="today-button"
          @clickbutton="() => date = moment()"
        >
          {{ $t('business_profile.booking_overview.today') }}
        </ui-button>
        <ui-datetimepicker
          :date.prop="date"
          data-test-id="date_selected-datepicker"
          skiptime
          @datechanged="({ detail }) => debounceOnChangeDatetimepicker(detail)"
        />

        <ui-button-segments
          :value="currentViewTypeIndex"
          :options.prop="views"
          :color="COLORS.primary"
          :default-color="GRAYSCALE.inkLight"
          class="Ui-ButtonSegments--minw-initial"
          data-test-id="set_days-segmented_button"
          @clickbuttonsegment="({ detail }) => currentViewType = find(views, ['value', detail])"
        />
      </div>
    </ui-card>

    <Daypilot
      ref="daypilot"
      :resources="resources"
      :events="events"
      :date="date"
      :view-type="currentViewType"
      :events-bubble="getEventsBubble"
      :resources-bubble="getResourcesBubble"
      :is-loading="isLoading"
      data-test-id="daypilot-component"
      @update:filter-resources="onFilterResources"
      @update:is-empty="isEmpty => isEmptyList = isEmpty"
    />
    <ui-loader v-if="isLoading" />
    <div
      v-else
      class="container pt-5"
    >
      <EmptyStateComponent
        v-if="isEmptyList"
        :src="emptyStateImage"
        :title="$t('business_profile.booking_overview.no_results')"
        has-background
        class="h-100 p-4 d-flex"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  camelCaseKeys, DATE_FORMAT, DELAY, LOG_TYPE, logger, SPEED,
} from '@emobg/web-utils';

import { external } from '@emobg/web-api-client';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import debounce from 'lodash/debounce';
import reject from 'lodash/reject';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import size from 'lodash/size';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import delay from 'lodash/delay';
import forEach from 'lodash/forEach';

// Components
import Daypilot from '@/vue/components/organism/Daypilot/Daypilot';
import ResourceBubble from '@/vue/components/organism/DaypilotBubble/ResourceBubble';
import EventBubble from '@/vue/components/organism/DaypilotBubble/EventBubble';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';

// Mixins
import { fetchCities, getCityCollection } from '@/stores/City/CityMapper';
import { getOperatorMileageSymbol } from '@/stores/CSOperator/CSOperatorMapper';
import NotificationsMixin from '@/mixins/Notifications';
// Const
import {
  DEFAULT_CITY_LIST,
  FILTER_TYPES,
  FLEET_OPTONS,
  VIEW_TYPES,
} from '@/vue/components/organism/CompanyAdmin/CompanyAdminBookingOverview/constants/bookingOverview';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { BOOKING_STATUS } from '@/constants/bookingStatus.const';
import { DATE_UNITS } from '@/constants/dates';

import { errorNotification } from '@/handlers/errorHandler.const';
import { nameSpace as profileNameSpace } from '@Profile/store';
import { nameSpace as companyNameSpace } from '@/vue/stores/Company/CompanyStore';

import { getOperatorsForLocations } from '@/vue/components/organism/CompanyAdmin/CompanyAdminBookingOverview/helpers/bookingOverviewHelpers';
import { isDedicatedFleet, isOpenFleet } from '@/helpers/booking/fleetHelpers';
import { emptyActiveBookings } from '@/utils/publicImages';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { NAMESPACE as OperatorLocationsNamespace } from '@/stores/OperatorLocations/OperatorLocationsModule';
import { fetchLocationByOperatorUuid } from '@/stores/OperatorLocations/OperatorLocationsMapper';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'CompanyAdminBookingOverview',

  components: {
    EmptyStateComponent,
    Daypilot,
  },

  mixins: [NotificationsMixin],
  setup() {
    const { notifyError } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      notifyError,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      resources: null,
      events: null,
      date: moment().startOf(DATE_UNITS.day),
      currentViewTypeIndex: 1,
      currentViewType: {},
      citySelected: null,
      cityList: null,
      fleetSelected: null,
      locationSelected: null,
      vehiclesCategorySelected: null,
      textSearched: null,
      filters: {},
      isEmptyList: false,
      isLoading: false,
      locations: [],
      operators: [],
    };
  },

  computed: {
    getOperatorMileageSymbol,
    ...mapGetters(profileNameSpace, ['getCurrentProfile']),
    ...mapGetters(companyNameSpace, ['getCompanyData']),
    ...mapGetters(OperatorLocationsNamespace, ['operatorLocations']),
    getCityCollection,
    displayedLocations() {
      return this.locations;
    },
  },

  async created() {
    this.emptyStateImage = emptyActiveBookings;

    this.currentViewTypeIndex = get(head(this.views), 'value', 1);
    this.currentViewType = find(this.views, ['value', this.currentViewTypeIndex]);

    this.FLEET_OPTONS = FLEET_OPTONS;
    this.FILTER_TYPES = FILTER_TYPES;

    this.cityList = DEFAULT_CITY_LIST;
    this.views = VIEW_TYPES;

    this.vehiclesCategoryList = [];
    this.citySelected = null;

    try {
      await this.fetchCities({ userProfileUuid: get(this.getCurrentProfile, 'uuid') });

      this.cityList = this.cityList.concat(this.getCityCollection(get(this.getCurrentProfile, 'uuid')));

      await this.getFleetAndBookings(this.citySelected);
    } catch (error) {
      logger.message(`There was a problem fetching availability suggestions: ${error.message}`, LOG_TYPE.error);
      this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
      this.notifyError(errorNotification(error));
    }
    this.operators = getOperatorsForLocations(this.getCompanyData);
    this.fetchAllLocations(this.operators);
  },
  methods: {
    get,
    isEmpty,
    find,
    fetchCities,
    fetchLocationByOperatorUuid,
    fetchAllLocations(operators) {
      const promises = map(operators, operator => this.fetchLocationByOperatorUuid({ operatorUuid: operator }));
      return Promise.all(promises)
        .then((results) => {
          this.locations = results.flat(1);
        })
        .catch(error => {
          logger.message(`There was a problem fetching locations: ${error.message}`, LOG_TYPE.error);
        });
    },
    resourcesTransformer(data) {
      const vehiclesCategory = [];
      const categories = [];

      const resources = data.map(location => {
        const locationVehicles = get(location, 'vehicles');
        if (!size(locationVehicles)) {
          return null;
        }

        const children = locationVehicles.map(vehicle => {
          const category = {
            label: vehicle.category,
            value: vehicle.category,
          };

          if (categories.indexOf(category.value) === -1) {
            categories.push(category.value);
            vehiclesCategory.push(category);
          }

          return {
            id: `resource-${vehicle.uuid}`,
            name: `${vehicle.brand} ${vehicle.model} - ${vehicle.licensePlate}`,
            extra: {
              vehicle,
            },
          };
        });

        const { vehicles, ...rest } = location;

        return {
          id: `${location.uuid}`,
          name: location.name,
          extra: {
            location: rest,
          },
          expanded: true,
          children,
        };
      });

      this.vehiclesCategoryList = [
        {
          label: this.$t('business_profile.booking_overview.filters.input_category_placeholder'),
          value: null,
        },
        ...vehiclesCategory,
      ];

      return resources.filter(item => item);
    },

    eventsTransformer(data) {
      return data.map(item => {
        let background = 'emobg-background-color-ink-lighter';
        if (
          item.status !== BOOKING_STATUS.finished
          && item.status !== BOOKING_STATUS.cancelled
        ) {
          background = 'emobg-background-color-success-light';
        }

        const user = get(item, 'user', {});
        const start = item.realStart || item.start;
        const end = item.realEnd || item.end;

        return {
          id: `event-${item.uuid}`,
          start: moment(start, DATE_FORMAT.filter).format(DATETIME_FORMATS.fullFormatTimezone),
          end: moment(end, DATE_FORMAT.filter).format(DATETIME_FORMATS.fullFormatTimezone),
          resource: `resource-${get(item, 'vehicle.uuid')}`,
          cssClass: `
            event
            event-type-${item.useCaseType}
            event-fleet-type-${item.useCaseFleet}
            event-profile-type-${item.useCaseProfile}
          `,
          html: `<span class="badge emobg-color-white ${background}">${item.status}</span> <span>${user.firstName} ${user.lastName}</span>`,
          extra: {
            booking: item,
          },
        };
      });
    },

    blocksTransformer(locations, blocks) {
      let vehicleUuId = '';
      const blockedEvents = [];

      const blockedVehicles = locations.flatMap(location => {
        const locationUuid = get(location, 'uuid');
        /// Get blocks for location
        const blockedLocation = pickBy(get(head(blocks), 'locations'),
          (value, key) => key === locationUuid);

        const locationVehicles = get(location, 'vehicles');
        if (!size(locationVehicles)) {
          return null;
        }

        return locationVehicles.flatMap(vehicle => {
          vehicleUuId = get(vehicle, 'uuid');
          const vehiclesMatchWithBlock = pickBy(get(head(values(blockedLocation)), 'vehicles'),
            (_value, key) => key === vehicleUuId);
          return get(head(values(vehiclesMatchWithBlock)), 'blocks');
        });
      });

      forEach(blockedVehicles, block => {
        blockedEvents.push({
          id: `event-${vehicleUuId}`,
          start: moment(block.start).format(DATETIME_FORMATS.fullFormatTimezone),
          end: moment(block.end).format(DATETIME_FORMATS.fullFormatTimezone),
          resource: `resource-${vehicleUuId}`,
          cssClass: 'event-type-blocked',
          clickDisabled: true,
          html: '',
        });
      });

      return blockedEvents;
    },

    debounceOnChangeDatetimepicker: debounce(
      async function onChangeDatetimepicker(value) {
        await this.onChangeDatetimepicker(value);
      },
      SPEED.medium,
    ),

    async onChangeDatetimepicker(value) {
      const date = moment(value, DATE_FORMAT.dob);
      await this.getFleetAndBookings(get(this.citySelected, 'uuid', null), date);
      this.date = date;
    },

    async getFleetAndBookings(city, date) {
      this.isLoading = true;

      const dateValue = date || this.date;
      const strDate = dateValue.format(DATE_FORMAT.filter);
      const strQueryParam = { start_date: strDate };

      const companyUuid = get(this.getCompanyData, 'uuid');

      let apiCalls = null;
      const operatorCities = reject(this.cityList, ['uuid', null]);
      const cities = isNil(city)
        ? map(operatorCities, 'uuid')
        : [city];

      apiCalls = cities.map(operatorCity => external.fleetCities.getCitiesCompanies(operatorCity, companyUuid, strQueryParam));

      this.resources = [];
      this.events = [];

      try {
        const responses = await Promise.all(apiCalls);
        let resources = [];
        let events = [];

        forEach(responses, cityData => {
          const cityLocations = camelCaseKeys(get(cityData, 'locations'));
          const cityBookings = camelCaseKeys(get(cityData, 'bookings'));
          const cityBlocks = get(cityData, 'blocks');

          if (size(cityLocations)) {
            resources = [
              ...resources,
              ...this.resourcesTransformer(cityLocations),
            ];
          }

          if (size(cityLocations) && size(cityBookings)) {
            events = [
              ...events,
              ...this.eventsTransformer(cityBookings),
            ];
          }

          if (size(cityBlocks)) {
            events = [
              ...events,
              ...this.blocksTransformer(cityLocations, cityBlocks),
            ];
          }
        });

        this.resources = resources;
        this.events = events;
        delay(() => {
          this.isLoading = false;
        }, DELAY.extraLong);
      } catch (error) {
        logger.message(`There was a problem fetching availability suggestions: ${error.message}`, LOG_TYPE.error);
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError(errorNotification(error));
      } finally {
        this.isLoading = false;
      }
    },

    filterBy(filterConfig, value) {
      const { type, validator } = filterConfig;
      if (validator(value)) {
        this.addFilter({
          type,
          filter: value,
        });
      } else {
        this.removeFilter(type);
      }
    },

    addFilter({ type, filter }) {
      this.filters = { ...this.filters, [type]: filter };
      this.syncDayPilotComponent(this.filters);
    },

    removeFilter(type) {
      this.filters = omit(this.filters, type);
      this.syncDayPilotComponent(this.filters);
    },

    syncDayPilotComponent(filters) {
      if (this.$refs.daypilot) {
        this.$refs.daypilot.filterResources(filters);
      }
    },

    removeAllFilters() {
      this.fleetSelected = null;
      this.locationSelected = null;
      this.vehiclesCategorySelected = null;
      this.textSearched = null;
      this.filters = {};

      this.syncDayPilotComponent(this.filters);
    },

    // TODO: [SBCSB-2095] Refactor this method, FIX fleet filter
    onFilterResources({ args }) {
      const {
        fleet_type: fleetType,
        location: locationUUID,
        vehicle_category: vehicleCategory,
        text,
      } = args.filter;

      // Filter by Fleet type when fleetType is not NULL
      // his level is 0 (row is level 0 when is a location)
      // or his level is 1 (row is level 1 when is a vehicle)
      // and parents is this location
      if (fleetType) {
        // How to know if user is filtering by Dedicated or Open fleet
        const dedicatedIsSelected = isDedicatedFleet(fleetType);
        const openIsSelected = isOpenFleet(fleetType);

        // fleetTypeShouldBe will be 0 when fleet type is dedicated
        // fleetTypeShouldBe will be 1 when fleet type is open
        let fleetTypeShouldBe = null;
        if (dedicatedIsSelected) {
          fleetTypeShouldBe = 0;
        }
        if (openIsSelected) {
          fleetTypeShouldBe = 1;
        }

        // For locations
        if (
          fleetTypeShouldBe !== null
          && args.row.level === 0
          && args.row.data.extra.location.open_location !== fleetTypeShouldBe
        ) {
          args.visible = false;
        }

        // For vehicles
        if (
          fleetTypeShouldBe !== null
          && args.row.level === 1
          && args.row.parent().data.extra.location.open_location
          !== fleetTypeShouldBe
        ) {
          args.visible = false;
        }
      }

      // Filter by Location when location is not NULL,
      // his level is 0 (row is level 0 when is a location)
      // or his level is 1 (row is level 1 when is a vehicle)
      // and parents is this location
      if (locationUUID) {
        // For locations
        if (
          args.row.level === 0
          && args.row.data.extra.location.uuid !== locationUUID
        ) {
          args.visible = false;
        }

        // For vehicles
        if (
          args.row.level === 1
          && args.row.parent().data.extra.location.uuid !== locationUUID
        ) {
          args.visible = false;
        }
      }

      // Filter by Vehicle category when vehicleCategory is not NULL,
      // his level is 1 (row is level 1 when is a vehicle)
      if (vehicleCategory && args.row.level === 1) {
        if (args.row.data.extra.vehicle.category !== vehicleCategory) {
          args.visible = false;
        }
      }

      // Filter by Text when filter input is greather than 3
      if (text) {
        // For locations
        if (
          args.row.level === 0
          && args.row.data.extra.location.name
            .toLowerCase()
            .indexOf(text.toLowerCase()) === -1
        ) {
          args.visible = false;
        }

        // For vehicles
        if (
          args.row.level === 1
          && args.row
            .parent()
            .data.extra.location.name.toLowerCase()
            .indexOf(text.toLowerCase()) === -1
          && args.row.data.extra.vehicle.licensePlate
            .toLowerCase()
            .indexOf(text.toLowerCase()) === -1
          && args.row.data.extra.vehicle.model
            .toLowerCase()
            .indexOf(text.toLowerCase()) === -1
          && args.row.data.extra.vehicle.brand
            .toLowerCase()
            .indexOf(text.toLowerCase()) === -1
        ) {
          args.visible = false;
        }
      }
    },

    getEventsBubble({ Bubble }) {
      return new Bubble({
        animated: false,
        showAfter: 300,
        onLoad: param => {
          if (isNil(get(param, 'source.data.extra'))) {
            return;
          }

          const { booking } = get(param, 'source.data.extra');
          const user = get(booking, 'user', {});

          const Component = Vue.extend(EventBubble);

          const mounted = new Component({
            propsData: {
              customer: {
                ...pick(user, ['firstName', 'lastName', 'email', 'phone']),
                costCenter: null,
              },
              reservation: {
                ...pick(booking, ['useCaseProfile', 'status', 'locationName', 'destinationLocationName', 'bookingTripType']),
                start: moment(booking.start, DATE_FORMAT.filter),
                end: moment(booking.end, DATE_FORMAT.filter),
              },
            },
          }).$mount().$el.outerHTML;
          param.html = mounted;
        },
      });
    },
    onSelectLocation(value) {
      this.locationSelected = value;
      return this.filterBy(FILTER_TYPES.location, get(this, 'locationSelected.uuid'));
    },
    getResourcesBubble({ Bubble, find: specificFind }) {
      return new Bubble({
        animated: false,
        showAfter: 300,
        onLoad: param => {
          const resource = specificFind(get(param, 'source.id'));
          const vehicle = get(resource, 'data.extra.vehicle');

          if (!isNil(vehicle)) {
            const Component = Vue.extend(ResourceBubble);
            const mounted = new Component({
              propsData: {
                vehicle: {
                  ...pick(vehicle, ['image', 'brand', 'model', 'category', 'transmission', 'fuelLevel', 'batteryLevel', 'engine']),
                  mileage: vehicle.km,
                  pax: vehicle.seats,
                },
                mileageSymbol: this.getOperatorMileageSymbol,
              },
            }).$mount().$el.outerHTML;
            param.html = mounted;
          }
        },
      });
    },
  },
};
</script>
